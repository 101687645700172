import { css } from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";

export const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author;
  const social = data.site.siteMetadata?.social;

  const avatar = data?.avatar?.childImageSharp?.fixed;

  return (
    <article css={bioStyle}>
      {avatar && (
        <section>
          <Image
            fixed={avatar}
            alt={author?.name || ``}
            css={imageStyle}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        </section>
      )}
      {author?.name && (
        <section>
          <p css={bioText}>{author.name}</p>
          <p css={bioText}> {author?.summary || null}</p>
          <a href={`https://twitter.com/${social?.twitter}`}>Twitter</a>
        </section>
      )}
    </article>
  );
};

const imageStyle = css({
  borderRadius: "100%",
  marginRight: "20px",
  marginBottom: "0",
});

const bioStyle = css({
  display: "flex",
  alignItems: "center",
  marginBottom: "70px",
});

const bioText = css({
  marginBottom: 0,
  fontSize: "16px",
});
